import detectEthereumProvider from "@metamask/detect-provider";
import { ethers, Contract } from "ethers";
import DiamondABI from "../contracts/diamondABI.json";
import GwmABI from "../contracts/gwmABI.json";
import ERC20ABI from "../contracts/ERC20ABI.json";

const getDiamond = async (bool_) => {
  let provider = await detectEthereumProvider();
  if (provider) {
    await provider.request({ method: "eth_requestAccounts" });
    provider = bool_
      ? new ethers.providers.JsonRpcProvider("https://polygon-rpc.com/")
      : new ethers.providers.Web3Provider(provider);

    const signer = provider.getSigner();
    const diamond = new Contract(
      "0x86935F11C86623deC8a25696E1C19a8659CbF95d",
      DiamondABI,
      bool_ ? provider : signer
    );

    //Returns a javascript object that points to our javascript contract
    return diamond;
  } else {
    return "install metamask";
  }
};

const getGwm = async () => {
  let provider = await detectEthereumProvider();
  if (provider) {
    await provider.request({ method: "eth_requestAccounts" });
    provider = new ethers.providers.Web3Provider(provider);

    const signer = provider.getSigner();
    const nursery = new Contract(
      "0x8eb8633c2694243c61cab3325769ee5afF5199A8",
      GwmABI,
      signer
    );

    //Returns a javascript object that points to our javascript contract
    return nursery;
  } else {
    return "install metamask";
  }
};

const getGwm2 = async () => {
  let provider = await detectEthereumProvider();
  if (provider) {
    await provider.request({ method: "eth_requestAccounts" });
    provider = new ethers.providers.Web3Provider(provider);

    const signer = provider.getSigner();
    const nursery = new Contract(
      "0xe91E698532B2de1E021d2c2650d7Bdb79DDF628D",
      GwmABI,
      signer
    );

    //Returns a javascript object that points to our javascript contract
    return nursery;
  } else {
    return "install metamask";
  }
};

const getNetworkId = async () => {
  let provider = await detectEthereumProvider();
  if (provider) {
    await provider.request({ method: "eth_requestAccounts" });
    return await provider.request({ method: "net_version" });
  } else {
    return "install metamask";
  }
};

export { getDiamond, getGwm, getGwm2, getNetworkId };
