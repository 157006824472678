import { Alert } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const styleBox = {
  padding: "0px 10px 10px 10px",
};

const styleAlert = {
  margin: "5px 0px",
};

export default function StatusAlert(props) {
  const propsIsUser = props.isUser;
  const propsWmaticBalance = props.wmaticBalance;
  const propsWmaticApproved = props.wmaticApproved;
  const propsInteractionApproved = props.interactionApproved;
  const propsCost = props.cost;

  return (
    <Box sx={styleBox}>
      {!propsIsUser && !propsInteractionApproved && (
        <Alert sx={styleAlert} severity="error">
          You didn't allow the contract to pet your Gotchis <br /> Please click
          "Approve Interaction".
        </Alert>
      )}
      {!propsIsUser && propsWmaticApproved < propsCost && (
        <Alert sx={styleAlert} severity="error">
          You don't have enough WMatic Approval to sign in. <br /> Change the
          amount by clicking "Edit Approval".
        </Alert>
      )}
      {!propsIsUser && propsWmaticBalance < propsCost && (
        <Alert sx={styleAlert} severity="error">
          You don't have enough WMatic in your wallet to sign in.
        </Alert>
      )}
      {propsIsUser && propsWmaticApproved < propsCost && (
        <Alert sx={styleAlert} severity="warning">
          You don't have enough WMatic Approval for next payment. <br /> Change
          the amount by clicking "Edit Approval".
        </Alert>
      )}
      {propsIsUser && propsWmaticBalance < propsCost && (
        <Alert sx={styleAlert} severity="warning">
          You don't have enough WMatic in your wallet for next payment.
        </Alert>
      )}
    </Box>
  );
}
