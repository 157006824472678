import detectEthereumProvider from "@metamask/detect-provider";
import { ethers, Contract } from "ethers";
import DiamondABI from "../contracts/diamondABI.json";
import FeeerABI from "../contracts/FeeerABI.json";
import ERC20ABI from "../contracts/ERC20ABI.json";

const getDiamond = async (bool_) => {
  let provider = await detectEthereumProvider();
  if (provider) {
    await provider.request({ method: "eth_requestAccounts" });
    provider = bool_
      ? new ethers.providers.JsonRpcProvider("https://polygon-rpc.com/")
      : new ethers.providers.Web3Provider(provider);

    const signer = provider.getSigner();
    const diamond = new Contract(
      "0x86935F11C86623deC8a25696E1C19a8659CbF95d",
      DiamondABI,
      bool_ ? provider : signer
    );

    //Returns a javascript object that points to our javascript contract
    return diamond;
  } else {
    return "install metamask";
  }
};

const getFeeer = async () => {
  let provider = await detectEthereumProvider();
  if (provider) {
    await provider.request({ method: "eth_requestAccounts" });
    provider = new ethers.providers.Web3Provider(provider);

    const signer = provider.getSigner();
    const nursery = new Contract(
      "0xcd6bADa9c0d8ABd3766363D91ff6CeDFDA257286",
      FeeerABI,
      signer
    );

    //Returns a javascript object that points to our javascript contract
    return nursery;
  } else {
    return "install metamask";
  }
};

const getWMatic = async () => {
  let provider = await detectEthereumProvider();
  if (provider) {
    await provider.request({ method: "eth_requestAccounts" });
    provider = new ethers.providers.Web3Provider(provider);

    const signer = provider.getSigner();
    const contract = new Contract(
      "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
      ERC20ABI,
      signer
    );

    //Returns a javascript object that points to our javascript contract
    return contract;
  } else {
    return "install metamask";
  }
};

const getNetworkId = async () => {
  let provider = await detectEthereumProvider();
  if (provider) {
    await provider.request({ method: "eth_requestAccounts" });
    return await provider.request({ method: "net_version" });
  } else {
    return "install metamask";
  }
};

export { getDiamond, getWMatic, getFeeer, getNetworkId };
