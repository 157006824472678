import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import Actions from "../components2/Actions";
import {
  getDiamond,
  getGwm,
  getGwm2,
  getNetworkId,
} from "../components2/Ethereum";
import MetaConnect from "../components2/MetaConnect";
import StatusAlert from "../components2/StatusAlert";
import StatusCard from "../components2/StatusCard";
import { useNavigate } from "react-router-dom";

import ReactGA from "react-ga4";

const styleBoxHow = {
  display: "flex",
  paddingTop: "5px",
};

const styleDialogQuestion = {
  fontWeight: 800,
};

const styleButtonOldContract = {
  margin: "auto",
  padding: "0px 5px",
  color: "rgba(225,225,225,0.9)",
  fontSize: "0.9rem",
  fontWeight: "bold",
  backgroundColor: "rgba(0,0,0,0.1)",
};

const styleButtonHow = {
  margin: "auto",
  padding: "0px 5px",
  color: "rgba(225,225,225,0.9)",
  fontSize: "0.7rem",
  backgroundColor: "rgba(0,0,0,0.1)",
};

export default function Petting(props) {
  // Google Analytics, page view
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/petting" });
  }, []);
  // Global functions to display errors and loading
  const propsHandleLoading = props.handleLoading;
  const propsHandleMessage = props.handleMessage;
  // Const data
  const pettingContractAddress = "0x290000C417a1DE505eb08b7E32b3e8dA878D194E";
  // MM Connection
  const [connected, setConnected] = useState(false);
  const [account, setAccount] = useState(null);
  // Contracts
  const [diamond, setDiamond] = useState(null);
  const [gwm, setGwm] = useState(null);
  const [networkId, setNetworkId] = useState(false);
  // Handling GWM 2
  const [isGwm1User, setIsGwm1User] = useState(false);
  const [hasDeposit, setHasDeposit] = useState(false);
  // Matic Data
  const [maticBalanceInContract, setMaticBalanceInContract] = useState(0);
  // Gotchi Data
  const [interactionApproved, setInteractionApproved] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [numGotchiWallet, setNumGotchiWallet] = useState(0);
  const [numGotchiLent, setNumGotchiLent] = useState(0);
  const [numGotchiBorrow, setNumGotchiBorrow] = useState(0);
  const [totalGotchis, setTotalGotchis] = useState(null);
  // Cost per Day
  const [cost, setCost] = useState(0);
  // Cost for 5 days
  const [cost5Days, setCost5Days] = useState(0);
  // Handle How It Works Modal
  const [openHow, setOpenHow] = useState(false);
  const handleOpenHow = () => setOpenHow((p) => !p);

  const navigate = useNavigate();
  const handleClickOldContract = (input) => {
    navigate("/petting_old");
  };

  // Runs once and before render to get the contract objects
  useEffect(() => {
    (async () => {
      await updatePettingData();
    })();
  }, [account]);

  // Runs after render, when connected changes state
  // Used to call theGraph and get the Gotchi datas
  useEffect(() => {
    (async () => {
      if (account === null) return;
      try {
        const url =
          "https://subgraph.satsuma-prod.com/tWYl5n5y04oz/aavegotchi/aavegotchi-core-matic/api";

        /**
         * One query to get gothcis: in Wallet, Lent and Borrowed
         */
        const query = `{
            _meta {
              block {
                number
              }
            }
            inWallet: aavegotchis(
              where: { owner: "${account.toLowerCase()}" }
            ) {
              id
            }
          
            lent: gotchiLendings(
              where: {
                lender: "${account.toLowerCase()}"
                completed: false
                cancelled: false
                borrower_not: "0x0000000000000000000000000000000000000000"
              }
            ) {
              id
            }
            borrowed: gotchiLendings(
              where: {
                completed: false
                cancelled: false
                borrower: "${account.toLowerCase()}"
              }
            ) {
              id
            }
          }
          `;

        const options = {
          method: "POST",
          body: JSON.stringify({
            query: query,
          }),
        };

        let res = await fetch(url, options);
        res = await res.json();
        res = res.data;

        const inWalletArray = res.inWallet;
        const lentArray = res.lent;
        const borrowedArray = res.borrowed;
        setNumGotchiWallet(inWalletArray.length);
        setNumGotchiLent(lentArray.length);
        setNumGotchiBorrow(borrowedArray.length);
        setTotalGotchis(inWalletArray.length + lentArray.length);
      } catch (err) {
        propsHandleMessage("Failed to get lent gotchis, reload page please");
      }
    })();
  }, [connected]);

  const updatePettingData = async () => {
    let tempAccount = account;
    if (tempAccount == null) {
      try {
        tempAccount = localStorage.getItem("account");
      } catch (error) {
        console.log("inPetting: Couldn't find account in local storage");
      }
    }

    if (!connected) return;
    if (tempAccount === null) return;
    propsHandleLoading(true, "Loading Data");
    let tempDiamond = await getDiamond();
    setDiamond(tempDiamond);
    // Saving tempGwm as GWM1
    let tempGwm = await getGwm();

    let tempNetworkId = await getNetworkId();
    setNetworkId(tempNetworkId);

    // Check if is gwm1 user
    let value = await tempGwm.getIsUser(tempAccount);
    if (value !== false) {
      setIsGwm1User(true);
    } else {
      // Check the balance in GWM1
      // let balance = await tempGwm.getUserBalance(tempAccount);
      let balance = await tempGwm.getUserBalance(tempAccount);

      if (balance > 0) {
        // If there is a balance with stay with GWM1
        setHasDeposit(true);
      } else {
        // Saving tempGwm as GWM2
        tempGwm = await getGwm2();
      }
    }

    setGwm(tempGwm);

    let tempInteractApproval = await tempDiamond.isPetOperatorForAll(
      tempAccount,
      pettingContractAddress
    );
    setInteractionApproved(tempInteractApproval);

    let tempIsUser = await tempGwm.getIsUser(tempAccount);
    setIsUser(tempIsUser);

    let oneDayCost =
      (await tempGwm.getCostPerDay(Number(totalGotchis))) / 10 ** 18;

    setCost(oneDayCost);
    const fiveDayCost = Number(oneDayCost * 5 + 0.1);
    setCost5Days(fiveDayCost);

    let tempBalance = (await tempGwm.getUserBalance(tempAccount)) / 10 ** 18;
    setMaticBalanceInContract(tempBalance);
    propsHandleLoading(false, "Loading Data");
  };

  // Runs after render, when connected changes state
  // In this case when the user is connected through the MetaConnect component
  useEffect(() => {
    (async () => {
      if (account === null || diamond === null) return;
      await updatePettingData();
    })();
  }, [totalGotchis]);

  return (
    <div>
      {/* MetaConnect component displays the buttons and handles the connection */}
      <MetaConnect
        setConnected={setConnected}
        connected={connected}
        setAccount={setAccount}
        account={account}
        handleMessage={propsHandleMessage}
      />

      {/* Old contract link */}
      <Box sx={styleBoxHow}>
        <Button sx={styleButtonOldContract} onClick={handleClickOldContract}>
          Click here to access the old WMatic contract
        </Button>
      </Box>

      {/* How it works modal */}
      <Dialog
        open={openHow}
        onClose={handleOpenHow}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"How it works?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* <Box sx={styleDialogQuestion}>How it works:</Box> */}
            You simply deposit Matic into the contract and decide when to start
            or stop the petting.
            <br />
            <br />
            Once you start the petting your balance will change daily depending
            on how many gotchis you have.
            <br />
            <br /> There is a starting fee of 0.1 Matic to avoid people starting
            the pet only when gas is high.
            <br />
            <br />
            You will need at least 5 days worth of Matic in your contract
            balance to start petting (including the 0.1 fees).
            <br />
            <br />
            <Box sx={styleDialogQuestion}>
              Does it pet gotchis I lent and borrowed?
            </Box>
            Yes, both lent and borrowed gotchis are pet. This can't be changed.
            <br />
            <br />
            <Box sx={styleDialogQuestion}>
              What happens if I get more gotchis after starting the petting?
            </Box>
            Your daily cost will change if you add or remove gotchis.
            <br />
            <br />
            <Box sx={styleDialogQuestion}>
              What happens if remove all my gotchis but don't stop petting?
            </Box>
            Your daily cost will be 0 until your wallet has at least 1 gotchi.
            <br />
            <br />
            <Box sx={styleDialogQuestion}>
              What happens if my balance goes to 0?
            </Box>
            If your balance goes to 0 you will be removed from the userbase and,
            if you start petting again, will have to pay the starting fees
            again.
            <br />
            <br />
            <Box sx={styleDialogQuestion}>
              I don't see the Withdraw Matic button, why?
            </Box>
            You need to stop petting if you want to withdraw your Matic.
            <br />
            <br />
            <Box sx={styleDialogQuestion}>
              Can the daily price change in the future?
            </Box>
            Yes, depending on the polygon network, the price can change. You
            must follow announcement in the Gotchi World discord server.
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Checking wrong network */}
      {connected && networkId && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={networkId !== "137"}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "2rem",
              backgroundColor: "rgba(0,0,0,0.4)",
            }}
          >
            Wrong Network
            <br />
            Please switch to Polygon and refresh this page
          </Typography>
        </Backdrop>
      )}
      <Container>
        {/* How it works button */}
        {connected && (
          <Box sx={styleBoxHow}>
            <Button sx={styleButtonHow} size="small" onClick={handleOpenHow}>
              How it works?
            </Button>
          </Box>
        )}

        {/* Card showing the current data of account */}
        <StatusCard
          connected={connected}
          totalGotchis={totalGotchis}
          interactionApproved={interactionApproved}
          isUser={isUser}
          cost={cost}
          maticBalanceInContract={maticBalanceInContract}
        />
        {/* Alert showing if there are any alerts for this account */}
        {connected && (
          <StatusAlert
            connected={connected}
            interactionApproved={interactionApproved}
            isUser={isUser}
            totalGotchis={totalGotchis}
            cost={cost5Days}
            maticBalanceInContract={maticBalanceInContract}
            isGwm1User={isGwm1User}
            hasDeposit={hasDeposit}
          />
        )}
        {/* Actions (buttons) display */}
        {connected && (
          <Actions
            interactionApproved={interactionApproved}
            isUser={isUser}
            diamond={diamond}
            gwm={gwm}
            totalGotchis={totalGotchis}
            cost={cost5Days}
            pettingContractAddress={pettingContractAddress}
            handleMessage={propsHandleMessage}
            handleLoading={propsHandleLoading}
            updatePettingData={updatePettingData}
            maticBalanceInContract={maticBalanceInContract}
            isGwm1User={isGwm1User}
            hasDeposit={hasDeposit}
          />
        )}
      </Container>
    </div>
  );
}
