import { Divider, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";

const paperStyle = {
  margin: "10px 10px 10px 10px",
  padding: "10px 20px",
  backgroundColor: "rgba(255, 255, 255, 0.2)",
  textAlign: "center",
};

const styleDivider = { margin: "3px 0px" };

const notcoBox = {
  fontSize: "20px",
  textAlign: "center",
};

export default function StatusCard(props) {
  const propsConnected = props.connected;
  const propsWmaticBalance = props.wmaticBalance;
  const propsWmaticApproved = props.wmaticApproved;
  const propsInteractionApproved = props.interactionApproved;
  const propsIsUser = props.isUser;
  const propsCost = props.cost;
  const propsTotalGotchis = props.totalGotchis;

  // UI isUser
  let userStatus;
  if (propsIsUser) {
    const style = {
      color: "green",
      fontWeight: "600",
    };
    userStatus = (
      <Typography sx={style}>Petting is on-going for your account</Typography>
    );
  } else {
    const style = {
      color: "red",
      fontWeight: "600",
    };
    userStatus = (
      <Typography sx={style}>You're currently not a user</Typography>
    );
  }

  return (
    <Paper sx={paperStyle}>
      {propsConnected ? (
        <Stack>
          <Box>{userStatus}</Box>
          <Divider style={styleDivider} />
          <Typography>
            Balance:{" "}
            <Box
              component="span"
              fontWeight={600}
            >{`${propsWmaticBalance.toFixed(2)}`}</Box>{" "}
            (WMatic)
          </Typography>
          <Divider style={styleDivider} />
          <Typography>
            Approval:{" "}
            <Box
              component="span"
              fontWeight={600}
            >{`${propsWmaticApproved.toFixed(2)}`}</Box>{" "}
            (WMatic)
          </Typography>
          <Divider style={styleDivider} />

          <Typography>
            Gotchis:{" "}
            <Box
              component="span"
              fontWeight={600}
            >{`${propsTotalGotchis}`}</Box>{" "}
            (Incl. borrowed)
          </Typography>
          <Divider style={styleDivider} />
          <Typography>
            30 Days Cost:{" "}
            <Box component="span" fontWeight={600}>{`${propsCost.toFixed(
              2
            )}`}</Box>{" "}
            (WMatic)
          </Typography>

          <Divider style={styleDivider} />
          <Typography>
            Gotchi Interaction:{" "}
            <Box
              component="span"
              fontWeight={600}
            >{`${propsInteractionApproved}`}</Box>
          </Typography>
        </Stack>
      ) : (
        <Box sx={notcoBox}>
          <Typography variant="h6">You're in the right place.</Typography>
          <Typography variant="h6">Please connect your wallet.</Typography>
        </Box>
      )}
    </Paper>
  );
}
