import { Box } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect } from "react";
import ProductCard from "../components/ProductCard";

import ImgCompaanion from "../images/compaanion.jpg";
import ImgPetting from "../images/petting.jpg";
import ImgStudio from "../images/studio.jpg";

import ReactGA from "react-ga4";

const boxStyle = {
  padding: "140px",
};

export default function Home(props) {
  // const propsHandleMessage = props.handleMessage;

  // Google Analytics, page view
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/" });
  }, []);

  return (
    <Box
      sx={{ boxStyle }}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box style={{ padding: "0px 0px 40px 0px" }}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 4 }}
        >
          <Box>
            <ProductCard
              title="Auto Petting"
              text="The most used automated petting for Aavegotchi"
              link="/petting"
              img={ImgPetting}
            />
          </Box>
          <Box>
            <ProductCard
              title="The Aavegotchi Compaanion"
              text="A chrome extension that helps you with your daily activites"
              link="https://www.compaanion.com"
              img={ImgCompaanion}
            />
          </Box>
          <Box>
            <ProductCard
              title="GW Studio"
              text="When art meets utility. First petting NFT."
              link="/studio"
              img={ImgStudio}
            />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
