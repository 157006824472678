import React from "react";
import "../index.scss";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <section className="header">
      <div className="title-wrapper" onClick={handleLogoClick}>
        <h1 className="sweet-title">
          <span data-text="Gotchi">Gotchi</span>
          <span data-text="World">World</span>
        </h1>
      </div>
    </section>
  );
}
