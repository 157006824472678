import { Alert } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const styleBox = {
  padding: "0px 10px 10px 10px",
};

const styleAlert = {
  margin: "5px 0px",
  maxWidth: "430px",
};

export default function StatusAlert(props) {
  const propsIsUser = props.isUser;
  const propsMaticBalanceInContract = props.maticBalanceInContract;
  const propsInteractionApproved = props.interactionApproved;
  const propsTotalGotchis = props.totalGotchis;
  const propsCost = props.cost;
  const propsIsGwm1User = props.isGwm1User;
  const propsHasDeposit = props.hasDeposit;

  if (propsIsGwm1User || propsHasDeposit) {
    return (
      <Box sx={styleBox}>
        <Alert sx={styleAlert} severity="error">
          ⚠️ This contract is deprecated. ⚠️
          <br />
          <br />
          A bug was found in the contract rendering paying automation difficult.
          <br />
          <br />
          You need to:
          <br />
          1. Stop the petting.
          <br />
          2. Withdraw your Matic.
          <br />
          3. Reload this page to see the new contract.
          <br />
          4. Join the new contract.
          <br />
          <br />
          The new contract works the same way.
          <br />
          <br />
          The changes are:
          <br />
          - New events (logs) to easily read what happens on-chain.
          <br />- A fix so that I can easily regulate users that add or remove
          gotchis in their wallet.
        </Alert>
      </Box>
    );
  } else {
    return (
      <Box sx={styleBox}>
        {!propsIsUser && !propsInteractionApproved && (
          <Alert sx={styleAlert} severity="error">
            You didn't allow the contract to pet your Gotchis <br /> Please
            click "Approve Interaction".
          </Alert>
        )}
        {!propsIsUser && propsMaticBalanceInContract < propsCost && (
          <Alert sx={styleAlert} severity="error">
            {`You don't have enough Matic deposited to start petting (require 5 days
          + 0.1 Matic = ${propsCost})`}
            .
          </Alert>
        )}
        {!propsIsUser && propsTotalGotchis == 0 && (
          <Alert sx={styleAlert} severity="error">
            {`You can't start petting with 0 Gotchis in your wallet`}.
          </Alert>
        )}
      </Box>
    );
  }
}
