import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React from "react";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/**
 *
 * @param {*} props bool:redisplaySnack fn:setRedisplaySnack int:duration
 * str:severity str:msg
 * @returns
 */
export default function DisplaySnack(props) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    props.setRedisplaySnack(false);
  };

  return (
    <Snackbar
      open={props.redisplaySnack}
      onClose={handleClose}
      autoHideDuration={props.duration ? props.duration : 10000}
    >
      <Alert
        onClose={handleClose}
        severity={`${props.severity}`}
        sx={{ width: "100%" }}
      >
        {props.msg}
      </Alert>
    </Snackbar>
  );
}
