import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { SocialIcon } from "react-social-icons";

const footerStyle = {
  padding: "20px",
};

const stackStyle = {
  placeContent: "center",
};
const iconSize = {
  height: 30,
  width: 30,
};

export default function Footer() {
  return (
    <footer>
      <Box sx={footerStyle}>
        <Stack sx={stackStyle} direction="row" spacing={2} alignItems="center">
          <SocialIcon
            url="https://twitter.com/TheGotchiWorld"
            target="_blank"
            rel="noopener noreferrer"
            style={iconSize}
          />
          <SocialIcon
            url="https://discord.com/invite/PV6Kg8jPX5"
            target="_blank"
            rel="noopener noreferrer"
            style={iconSize}
          />
        </Stack>
      </Box>
    </footer>
  );
}
