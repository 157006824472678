import { Box, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";

import ReactGA from "react-ga4";

const paperStyle = {
  margin: "40px 10px 40px 10px",
  padding: "10px 40px",
  backgroundColor: "rgba(255, 255, 255, 0.2)",
  textAlign: "center",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

const styleTitle = {
  fontSize: "22px",
  fontWeight: "bold",
  margin: "0 0 20px 0",
};

const styleContainer1 = {
  display: "flex",
  flexDirection: "row",
};

export default function Studio() {
  // Google Analytics, page view
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/studio" });
  }, []);
  return (
    <Box>
      <Paper sx={paperStyle}>
        <Typography sx={styleTitle}>Your own personal petting bot</Typography>
        <Box sx={styleContainer1}>
          <Box>
            <Typography>
              Gotchi World's FAKE Gotchis are one of a kind.
              <br />
              It is the first ever FAKE tied to a smart contract.
              <br />
              Using Chainlink, it allows you to own your petting bot.
              <br />
              Start it, stop it and sell it whenever you want.
              <br />
              You won't rely on 3rd party applications anymore!
              <br />
              <br />
            </Typography>
            <Typography>
              It takes 3 step to set it up.
              <br />
              <br />
              <a
                href="https://github.com/0xCeza/GotchiWorld_FakeGotchis"
                target="_blank"
                rel="noreferrer noopener"
              >
                Click here to see the detailed step by step.
              </a>
              <br />
              <br />
              <a
                href="https://github.com/0xCeza/GotchiWorld_FakeGotchis#faq"
                target="_blank"
                rel="noreferrer noopener"
              >
                Click here to access the FAQ.
              </a>
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
