import { Button, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const styleAddress = {
  padding: "2px 5px",
  color: "#F8F8F8",
  backgroundColor: "rgba(63, 161, 251, 1)",
};

export default function MetaConnect(props) {
  const propsHandleMessage = props.handleMessage;

  // UI
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  // Normal size
  let styleBox = {
    position: "absolute",
    right: "30px",
    top: "20px",
  };

  if (isSmall) {
    styleBox = {
      position: "absolute",
      right: "10px",
      top: "10px",
    };
  }

  // Exe
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", accountsChanged);
      window.ethereum.on("chainChanged", chainChanged);

      const account = localStorage.getItem("account");

      if (account && account !== undefined && account !== "undefined") {
        props.setAccount(account);
        props.setConnected(true);
      }
    }
  }, []);

  const connectHandler = async () => {
    if (window.ethereum) {
      try {
        const res = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        await accountsChanged(res);
      } catch (err) {
        propsHandleMessage(
          "error",
          err,
          "There was a problem connecting to MetaMask"
        );
      }
    } else {
      propsHandleMessage("error", "Install MetaMask");
    }
  };

  const accountsChanged = async (newAccount) => {
    newAccount = newAccount[0];
    localStorage.setItem("account", newAccount);
    props.setAccount(newAccount);
    props.setConnected(true);
  };

  const chainChanged = () => {
    localStorage.removeItem("account");
    props.setAccount(null);
    props.setConnected(false);
  };

  return (
    <div>
      <Box sx={styleBox}>
        {props.connected ? (
          <Paper sx={{ overflow: "auto" }} elevation={3}>
            <Typography sx={styleAddress} variant="body2">
              {props.account.slice(0, 6)}...{props.account.slice(38, 42)}
            </Typography>
          </Paper>
        ) : (
          <Button variant="contained" onClick={connectHandler}>
            Connect
          </Button>
        )}
      </Box>
    </div>
  );
}
