import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.scss";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const THEME = createTheme({
  typography: {
    fontFamily: `"Inter", "Helvetica", "Arial", sans-serif`,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        // root: {
        //   borderRadius: "4px",
        //   backgroundColor: "rgba(63, 161, 251, 1)",
        // },
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              borderRadius: "4px",
              backgroundColor: "rgba(63, 161, 251, 1)",
            }),
          ...(ownerState.variant === "outlined" &&
            ownerState.color === "primary" && {
              borderRadius: "4px",
              // backgroundColor: "rgba(63, 161, 251, 1)",
            }),
          ...(ownerState.color === "secondary" && {
            color: "rgba(252, 70, 168, 1)",
          }),
        }),
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={THEME}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
