import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import Actions from "../components/Actions";
import {
  getDiamond,
  getFeeer,
  getWMatic,
  getNetworkId,
} from "../components/Ethereum";
import MetaConnect from "../components/MetaConnect";
import StatusAlert from "../components/StatusAlert";
import StatusCard from "../components/StatusCard";

import ReactGA from "react-ga4";

const styleBoxHow = {
  display: "flex",
};

const styleDialogQuestion = {
  fontWeight: 800,
};

const styleOldContractMessage = {
  margin: "auto",
  padding: "13px",
  color: "rgba(63, 161, 251, 1)",
  fontSize: "1.2rem",
  fontWeight: "bold",
  textAlign: "center",
  backgroundColor: "rgba(0,0,0,0.5)",
};

const styleButtonHow = {
  margin: "auto",
  padding: "0px 5px",
  color: "rgba(225,225,225,0.9)",
  fontSize: "0.7rem",
  backgroundColor: "rgba(0,0,0,0.1)",
};

export default function Petting(props) {
  // Google Analytics, page view
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/petting" });
  }, []);
  // Global functions to display errors and loading
  const propsHandleLoading = props.handleLoading;
  const propsHandleMessage = props.handleMessage;
  // Const data
  const feeerContractAddress = "0xcd6bADa9c0d8ABd3766363D91ff6CeDFDA257286";
  const pettingContractAddress = "0x290000C417a1DE505eb08b7E32b3e8dA878D194E";
  // MM Connection
  const [connected, setConnected] = useState(false);
  const [account, setAccount] = useState(null);
  // Contracts
  const [diamond, setDiamond] = useState(null);
  const [feeer, setFeeer] = useState(null);
  const [wmatic, setWmatic] = useState(null);
  const [networkId, setNetworkId] = useState("0");
  // Gotchi Data
  const [interactionApproved, setInteractionApproved] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [numGotchiWallet, setNumGotchiWallet] = useState(0);
  const [numGotchiLent, setNumGotchiLent] = useState(0);
  const [numGotchiBorrow, setNumGotchiBorrow] = useState(0);
  const [totalGotchis, setTotalGotchis] = useState(null);
  // WMatic Data
  const [wmaticApproved, setWmaticApproved] = useState(0);
  const [wmaticBalance, setWmaticBalance] = useState(0);
  // Cost per 30 Days
  const [cost, setCost] = useState(0);
  // Handle How It Works Modal
  const [openHow, setOpenHow] = useState(false);
  const handleOpenHow = () => setOpenHow((p) => !p);

  // Runs once and before render to get the contract objects
  useEffect(() => {
    (async () => {
      setDiamond(await getDiamond());
      setFeeer(await getFeeer());
      setWmatic(await getWMatic());
      setNetworkId(await getNetworkId());
    })();
  }, []);

  // Runs after render, when connected changes state
  // Used to call theGraph and get the Gotchi datas
  useEffect(() => {
    (async () => {
      if (account === null) return;
      try {
        const url =
          "https://subgraph.satsuma-prod.com/tWYl5n5y04oz/aavegotchi/aavegotchi-core-matic/api";

        /**
         * One query to get gothcis: in Wallet, Lent and Borrowed
         */
        const query = `{
          _meta {
            block {
              number
            }
          }
          inWallet: aavegotchis(
            where: { owner: "${account.toLowerCase()}" }
          ) {
            id
          }
        
          lent: gotchiLendings(
            where: {
              lender: "${account.toLowerCase()}"
              completed: false
              cancelled: false
              borrower_not: "0x0000000000000000000000000000000000000000"
            }
          ) {
            id
          }
          borrowed: gotchiLendings(
            where: {
              completed: false
              cancelled: false
              borrower: "${account.toLowerCase()}"
            }
          ) {
            id
          }
        }
        `;

        const options = {
          method: "POST",
          body: JSON.stringify({
            query: query,
          }),
        };

        let res = await fetch(url, options);
        res = await res.json();
        res = res.data;

        const inWalletArray = res.inWallet;
        const lentArray = res.lent;
        const borrowedArray = res.borrowed;
        setNumGotchiWallet(inWalletArray.length);
        setNumGotchiLent(lentArray.length);
        setNumGotchiBorrow(borrowedArray.length);
        setTotalGotchis(inWalletArray.length + lentArray.length);
      } catch (err) {
        propsHandleMessage("Failed to get lent gotchis, reload page please");
      }
    })();
  }, [connected]);

  const updatePettingData = async () => {
    setInteractionApproved(
      await diamond.isPetOperatorForAll(account, pettingContractAddress)
    );

    setIsUser(await feeer.getIsSignedUp(account));

    setWmaticApproved(
      (await wmatic.allowance(account, feeerContractAddress)) / 10 ** 18
    );

    setWmaticBalance((await wmatic.balanceOf(account)) / 10 ** 18);

    setCost(
      (await feeer.getWmaticPayPerGotchis(Number(totalGotchis))) / 10 ** 18
    );
  };

  // Runs after render, when connected changes state
  // In this case when the user is connected through the MetaConnect component
  useEffect(() => {
    (async () => {
      if (account === null || diamond === null) return;
      await updatePettingData();
    })();
  }, [totalGotchis]);

  return (
    <div>
      {/* MetaConnect component displays the buttons and handles the connection */}
      <MetaConnect
        setConnected={setConnected}
        connected={connected}
        setAccount={setAccount}
        account={account}
        handleMessage={propsHandleMessage}
      />

      {/* Message about contract old */}
      <Box sx={styleBoxHow}>
        <Typography sx={styleOldContractMessage}>
          This contract is now deprecated you can only stop petting <br /> The
          new petting contract uses Matic instead of WMatic
        </Typography>
      </Box>

      {/* How it works modal */}
      {/* <Dialog
        open={openHow}
        onClose={handleOpenHow}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"How it works?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box sx={styleDialogQuestion}>
              What's the difference between Matic and WMatic?
            </Box>
            Matic is the official coin used to pay gas on the polygon chain. But
            it doesn't work the same as other tokens like GHST. This is why you
            can WRAP Matic. <br />
            You can use your favorite Polygon DEX to wrap Matic into WMatic with
            no slippage.
            <br />
            <br />
            <Box sx={styleDialogQuestion}>
              What happens if I get more gotchis after signing up?
            </Box>
            A bot will regulate the amount for the remaining days. Example:{" "}
            <br />
            If you have 3 Gotchis you will pay 1 WMatic when signing up. After
            15 Days you buy 3 gotchis. Your new cost should be 2 WMatic per 30
            days. The bot will take 0.5 WMatic.
            <br />
            You should pay 2 Wmatic but already paid 1 and only 15 days are
            remaining: 2 - (1 / (30 / 15)) = 0.5 WMatic. Simple, right?
            <br />
            <br />
            <Box sx={styleDialogQuestion}>
              What happens if I don't have any WMatic in my wallet but still get
              3 more gotchis?
            </Box>
            If the bot can't charge you, it will remove you as a user.
            <br />
            <br />
            <Box sx={styleDialogQuestion}>Can I sign up for 12 months?</Box>
            No, when you sign up it takes the cost displayed for 30 days. After
            30 days, if you have enough approval and WMatic, it will auto-renew
            your subscription.
            <br />
            <br />
            <Box sx={styleDialogQuestion}>
              My cost is 10 WMatic, should I approve only 10 WMatic?
            </Box>
            If you approve 10 WMatic, when you sign up the contract will take 10
            WMatic for the first 30 days. After that, your approval will be 0
            WMatic.
          </DialogContentText>
        </DialogContent>
      </Dialog> */}

      {/* Main container */}
      {connected && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={networkId !== "137"}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "2rem",
              backgroundColor: "rgba(0,0,0,0.4)",
            }}
          >
            Wrong Network
            <br />
            Please switch to Polygon and refresh this page
          </Typography>
        </Backdrop>
      )}
      <Container>
        {/* How it works button */}

        {/* {connected && (
          <Box sx={styleBoxHow}>
            <Button sx={styleButtonHow} size="small" onClick={handleOpenHow}>
              How it works?
            </Button>
          </Box>
        )} */}
        <StatusCard
          connected={connected}
          totalGotchis={totalGotchis}
          wmaticBalance={wmaticBalance}
          wmaticApproved={wmaticApproved}
          interactionApproved={interactionApproved}
          isUser={isUser}
          cost={cost}
        />
        {connected && (
          <StatusAlert
            connected={connected}
            wmaticBalance={wmaticBalance}
            wmaticApproved={wmaticApproved}
            interactionApproved={interactionApproved}
            isUser={isUser}
            cost={cost}
          />
        )}
        {connected && (
          <Actions
            interactionApproved={interactionApproved}
            isUser={isUser}
            diamond={diamond}
            wmatic={wmatic}
            feeer={feeer}
            wmaticBalance={wmaticBalance}
            wmaticApproved={wmaticApproved}
            totalGotchis={totalGotchis}
            cost={cost}
            feeerContractAddress={feeerContractAddress}
            pettingContractAddress={pettingContractAddress}
            setWmaticApproved={setWmaticApproved}
            handleMessage={propsHandleMessage}
            handleLoading={propsHandleLoading}
            updatePettingData={updatePettingData}
          />
        )}
      </Container>
    </div>
  );
}
