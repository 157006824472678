import { Button, Modal, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { Box } from "@mui/system";

// Modal user when click "Edit Approval"
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 260,
  bgcolor: "white",
  border: "2px inset rgb(63, 161, 251)",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "row",
};

const styleTextField = {
  width: 150,
  textAlign: "center",
};

const styleButtonBox = {
  // alignItems: "end",
  display: "flex",
  marginLeft: "auto",
};

const styleButton = {};

const styleBox = {
  padding: "0px 10px",
};

const txOpt = {
  gasLimit: 250000,
};

export default function Actions(props) {
  // From App
  const propsHandleLoading = props.handleLoading;
  const propsHandleMessage = props.handleMessage;

  // From Petting
  const propsInteractionApproved = props.interactionApproved;
  const propsIsUser = props.isUser;
  const propsDiamond = props.diamond;
  const propsWmatic = props.wmatic;
  const propsFeeer = props.feeer;
  const propsFeeerContractAddress = props.feeerContractAddress;
  const propsPettingContractAddress = props.pettingContractAddress;
  const propsSetWmaticApproved = props.setWmaticApproved;
  const propsUpdatePettingData = props.updatePettingData;
  const propsTotalGotchis = props.totalGotchis;
  const propsCost = props.cost;
  const propsWmaticBalance = props.wmaticBalance;
  const propsWmaticApproved = props.wmaticApproved;

  // Modal Management
  const [approvalModal, setApprovalModal] = useState(false);
  const [amountToAprove, setAmountToAprove] = useState(100);
  const handleClose = () => setApprovalModal(false);

  const approveInteraction = async () => {
    // Send the tx
    try {
      // Start loading
      propsHandleLoading(true, "Awaiting for the transaction to be confirmed");
      const tx = await propsDiamond.setPetOperatorForAll(
        propsPettingContractAddress,
        true,
        txOpt
      );
      const res = await tx.wait();
      if (res.status === 1)
        propsHandleMessage(
          "success",
          "Transaction successful. Refresh this page."
        );
      else {
        propsHandleMessage("error", "Transaction failed");
      }
    } catch (err) {
      propsHandleMessage("error", err, "Failed to send the transacion");
    }

    // Stop loading
    propsHandleLoading(
      false,
      "Transaction Mined successfully, you'll need to refresh the page to see the data update in the UI"
    );
  };

  // Display the Edit Approval modal
  const handleClickEditApproval = () => setApprovalModal(true);

  const handleChangeInputApproval = (event) => {
    const value = event.target.value;
    setAmountToAprove(value);
  };

  const editApproval = async () => {
    // First, hide the approval modal
    setApprovalModal(false);

    // Send the tx
    try {
      // Start loading
      propsHandleLoading(true, "Awaiting for the transaction to be confirmed");

      const tx = await propsWmatic.approve(
        propsFeeerContractAddress,
        ethers.utils.parseEther(`${amountToAprove}`),
        txOpt
      );

      const res = await tx.wait();
      if (res.status === 1)
        propsHandleMessage(
          "success",
          "Transaction successful. Refresh this page."
        );
      else {
        propsHandleMessage("error", "Transaction failed");
      }
    } catch (err) {
      propsHandleMessage("error", err, "Failed to send the Tx");
    }

    // Stop loading
    propsHandleLoading(
      false,
      "Transaction Mined successfully, you'll need to refresh the page to see the data update in the UI"
    );
  };

  const signUp = async () => {
    // Send the tx
    try {
      // Start loading
      propsHandleLoading(true, "Awaiting for the transaction to be confirmed");
      const tx = await propsFeeer.signUp(Number(propsTotalGotchis), txOpt);
      const res = await tx.wait();
      if (res.status === 1)
        propsHandleMessage(
          "success",
          "Transaction successful. Refresh this page."
        );
      else {
        propsHandleMessage("error", "Transaction failed");
      }
    } catch (err) {
      propsHandleMessage("error", err, "Failed to send the Transaction");
    }

    // Stop loading
    propsHandleLoading(
      false,
      "Transaction Mined successfully, you'll need to refresh the page to see the data update in the UI"
    );
  };

  const leave = async () => {
    // Send the tx
    try {
      // Start loading
      propsHandleLoading(true, "Awaiting for the transaction to be confirmed");
      const tx = await propsFeeer.leave(txOpt);
      const res = await tx.wait();
      if (res.status === 1)
        propsHandleMessage(
          "success",
          "Transaction successful. Refresh this page."
        );
      else {
        propsHandleMessage("error", "Transaction failed");
      }
    } catch (err) {
      propsHandleMessage("error", err, "Failed to send the Transaction");
    }

    // Stop loading
    propsHandleLoading(
      false,
      "Transaction Mined successfully, you'll need to refresh the page to see the data update in the UI"
    );
  };

  return (
    <Box>
      {/* Edit Approval popup modal */}
      {/* <Modal open={approvalModal} onClose={handleClose}>
        <Box sx={modalStyle}>
          <TextField
            sx={styleTextField}
            id="outlined-basic"
            label="Amount To Approve"
            variant="outlined"
            defaultValue={100}
            onChange={handleChangeInputApproval}
          />
          <Box sx={styleButtonBox}>
            <Button sx={styleButton} variant="contained" onClick={editApproval}>
              Apply
            </Button>
          </Box>
        </Box>
      </Modal> */}
      {/* Display the tx has been successfully mined */}

      {/* Buttons stack */}
      <Box sx={styleBox}>
        {propsInteractionApproved ? (
          <Stack sx={{}} direction="row">
            <Button
              disabled={true}
              variant="contained"
              onClick={handleClickEditApproval}
            >
              Edit Approval
            </Button>
            {propsIsUser ? (
              <Button
                variant="contained"
                onClick={leave}
                sx={{ marginLeft: "auto" }}
              >
                Leave
              </Button>
            ) : (
              <Button
                disabled={true}
                // disabled={
                //   !(
                //     propsInteractionApproved &&
                //     propsWmaticApproved >= propsCost &&
                //     propsWmaticBalance >= propsCost
                //   )
                // }
                variant="contained"
                onClick={signUp}
                sx={{ marginLeft: "auto" }}
              >
                Sign Up
              </Button>
            )}
          </Stack>
        ) : (
          <Button variant="contained" onClick={approveInteraction}>
            Approve Interaction
          </Button>
        )}
      </Box>
    </Box>
  );
}
