import { Divider, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";

const paperStyle = {
  margin: "10px 10px 10px 10px",
  padding: "10px 20px",
  backgroundColor: "rgba(255, 255, 255, 0.2)",
  textAlign: "center",
};

const styleDivider = { margin: "3px 0px" };

const notcoBox = {
  fontSize: "20px",
  textAlign: "center",
};

export default function StatusCard(props) {
  const propsConnected = props.connected;
  const propsInteractionApproved = props.interactionApproved;
  const propsIsUser = props.isUser;
  const propsCost = props.cost;
  const propsTotalGotchis = props.totalGotchis;
  const propsMaticBalanceInContract = props.maticBalanceInContract;

  // UI isUser
  let userStatus;
  if (propsIsUser) {
    const style = {
      color: "green",
      fontWeight: "600",
    };
    userStatus = <Typography sx={style}>Petting is ON</Typography>;
  } else {
    const style = {
      color: "red",
      fontWeight: "600",
    };
    userStatus = <Typography sx={style}>Petting is OFF</Typography>;
  }

  return (
    <Paper sx={paperStyle}>
      {propsConnected ? (
        <Stack>
          <Box>{userStatus}</Box>
          <Divider style={styleDivider} />
          <Typography>
            Balance in contract:{" "}
            <Box
              component="span"
              fontWeight={600}
            >{`${propsMaticBalanceInContract.toFixed(2)}`}</Box>{" "}
            (Matic)
          </Typography>

          <Divider style={styleDivider} />

          <Typography>
            Gotchis:{" "}
            <Box
              component="span"
              fontWeight={600}
            >{`${propsTotalGotchis}`}</Box>{" "}
            (Incl. borrowed and lent)
          </Typography>
          <Divider style={styleDivider} />
          <Typography>
            Daily Cost:{" "}
            <Box component="span" fontWeight={600}>{`${propsCost.toFixed(
              2
            )}`}</Box>{" "}
            (Matic)
          </Typography>
          <Divider style={styleDivider} />
          <Typography>
            Starting fee (one time):{" "}
            <Box component="span" fontWeight={600}>{`0.1`}</Box> (Matic)
          </Typography>
          <Divider style={styleDivider} />
          <Typography>
            Gotchi Interaction:{" "}
            <Box
              component="span"
              fontWeight={600}
            >{`${propsInteractionApproved}`}</Box>
          </Typography>
        </Stack>
      ) : (
        <Box sx={notcoBox}>
          <Typography variant="h6">You're in the right place.</Typography>
          <Typography variant="h6">Please connect your wallet.</Typography>
        </Box>
      )}
    </Paper>
  );
}
