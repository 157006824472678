import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DisplaySnack from "./components/DisplaySnack";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LoadingModal from "./components/LoadingModal";

import Home from "./pages/Home";
import Petting from "./pages/Petting";
import Petting2 from "./pages/Petting2";
import Studio from "./pages/Studio";

import ReactGA from "react-ga4";

function App() {
  // Init Google Analytics
  ReactGA.initialize("G-DWC8JP224Q");
  // Google Analytics, page view
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);

  // Snackbar message display
  const [message, setMessage] = useState(null);
  const [severity, setSeverity] = useState(null);
  const [redisplaySnack, setRedisplaySnack] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false); // Loading gif
  const [open, setOpen] = useState(false); // Opening/Closing modal
  const [loadingMessage, setLoadingMessage] = useState(null);

  // Loading functions
  const handleLoading = (status, msg) => {
    setLoadingMessage(msg);
    setLoading(status);
    setOpen(true);
  };

  // Snackbar functions
  const metamaskErrorHandler = (code) => {
    if (code === -32002) return "A connection to Metamask is already pending.";
    if (code === 4001) return "User rejected the transaction.";
    return;
  };

  const handleMessage = (severity, msg, defaultMsg = msg) => {
    setSeverity(severity);
    if (severity === "error") {
      console.error(msg);
      let errMsg;
      if (msg.code) {
        errMsg = metamaskErrorHandler(msg.code);
      }
      errMsg ? console.log(errMsg) : console.log(defaultMsg);
      errMsg ? setMessage(errMsg) : setMessage(defaultMsg);
    } else {
      setMessage(msg);
    }
    setRedisplaySnack(true);
  };

  return (
    <>
      <LoadingModal
        loading={loading}
        setLoading={setLoading}
        setOpen={setOpen}
        loadingMessage={loadingMessage}
      />
      <Box
        // className="animatedBg"
        className="bg"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box sx={{ padding: "1rem 3rem" }}>
          <BrowserRouter>
            <Header />
            <Routes>
              <Route
                path="/"
                element={
                  <Home
                    handleMessage={handleMessage}
                    handleLoading={handleLoading}
                  />
                }
              />
              <Route
                path="/petting_old"
                element={
                  <Petting
                    handleMessage={handleMessage}
                    handleLoading={handleLoading}
                  />
                }
              />
              <Route
                path="/studio"
                element={
                  <Studio
                    handleMessage={handleMessage}
                    handleLoading={handleLoading}
                  />
                }
              />
              <Route
                path="/petting"
                element={
                  <Petting2
                    handleMessage={handleMessage}
                    handleLoading={handleLoading}
                  />
                }
              />
            </Routes>
          </BrowserRouter>
        </Box>
      </Box>

      <Footer />

      {message && (
        <DisplaySnack
          msg={message}
          severity={severity}
          open={true}
          duration={6000}
          setRedisplaySnack={setRedisplaySnack}
          redisplaySnack={redisplaySnack}
        />
      )}
    </>
  );
}

export default App;
