import { Button, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import "../index.scss";

const stylePaper = {
  margin: "5px",
  // padding: "5px",
  border: "2px inset rgb(63, 161, 251)",
  width: "210px",
  height: "100%",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
};

const styleTitleBox = {
  display: "flex",
  flexDirection: "column",
  height: "60px",
  justifyContent: "center",
  margin: "0px 5px 5px 5px",
};

const styleTitle = {
  fontFamily: '"Exo 2", sans-serif',
  fontSize: "1.2rem",
  fontWeight: "bold",
  margin: "5px 10px",
  textAlign: "center",
};

const styleText = {
  marginTop: "auto",
  marginBottom: "5px",
  marginLeft: "15px",
  marginRight: "15px",
  fontSize: "1rem",
  lineHeight: "1.15rem",
  textAlign: "center",
};

const styleButton = {
  display: "flex",
  width: "100%",
  marginBottom: "10px",
  marginTop: "15px",
  alignItems: "end",
};

export default function ProductCard(props) {
  const propsImg = props.img;
  const propsLink = props.link;
  const navigate = useNavigate();

  const styleBg = {
    backgroundImage: `url(${propsImg})`,
    backgroundPosition: "right 0px top -16px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% auto",
    height: "90px",
    marginBottom: "5px",
  };

  const handleClick = (input) => {
    if (input.slice(0, 1) === "/") {
      navigate(input);
    } else {
      window.open(input, "_blank").focus();
    }
  };

  return (
    <Paper
      sx={stylePaper}
      elevation={9}
      onClick={() => {
        handleClick(propsLink);
      }}
    >
      {/* <Box className="bgPaper"></Box> */}
      <Box sx={styleBg}></Box>
      <Box sx={styleTitleBox}>
        <Typography variant="body1" sx={styleTitle}>
          {props.title}
        </Typography>
      </Box>
      <Typography variant="body2" sx={styleText}>
        {props.text}
      </Typography>
      <Button sx={styleButton} color="secondary" size="small">
        Click here
      </Button>
    </Paper>
  );
}
