import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { Box } from "@mui/system";

// Modal user when click "Deposit"
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 260,
  bgcolor: "white",
  border: "2px inset rgb(63, 161, 251)",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "row",
};

const styleTextField = {
  width: 150,
  textAlign: "center",
};

const styleButtonBox = {
  display: "flex",
  marginLeft: "auto",
};

const styleButton = {};

const styleBox = {
  padding: "0px 10px",
};

const styleDisclaimer = {
  padding: "0px 10px",
  maxWidth: "430px",
  marginBottom: "10px",
};

const txOpt = {
  gasLimit: 250000,
};

export default function Actions(props) {
  // From App
  const propsHandleLoading = props.handleLoading;
  const propsHandleMessage = props.handleMessage;

  // From Petting
  const propsInteractionApproved = props.interactionApproved;
  const propsIsUser = props.isUser;
  const propsDiamond = props.diamond;
  const propsWmatic = props.wmatic;
  const propsGwm = props.gwm;
  const propsFeeerContractAddress = props.feeerContractAddress;
  const propsPettingContractAddress = props.pettingContractAddress;
  const propsSetWmaticApproved = props.setWmaticApproved;
  const propsUpdatePettingData = props.updatePettingData;
  const propsTotalGotchis = props.totalGotchis;
  const propsCost = props.cost;
  const propsMaticBalanceInContract = props.maticBalanceInContract;
  const propsIsGwm1User = props.isGwm1User;
  const propsHasDeposit = props.hasDeposit;

  // Modal Management
  const [approvalModal, setApprovalModal] = useState(false);
  const [amountToDeposit, setAmountToDeposit] = useState(10);
  const handleClose = () => setApprovalModal(false);

  // Disclaimer
  const [checked, setChecked] = useState(false);

  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };

  const approveInteraction = async () => {
    // Send the tx
    try {
      // Start loading
      propsHandleLoading(true, "Awaiting for the transaction to be confirmed");
      const tx = await propsDiamond.setPetOperatorForAll(
        propsPettingContractAddress,
        true,
        txOpt
      );
      const res = await tx.wait();
      if (res.status === 1)
        propsHandleMessage(
          "success",
          "Transaction successful. Refresh this page."
        );
      else {
        propsHandleMessage("error", "Transaction failed");
      }
    } catch (err) {
      propsHandleMessage("error", err, "Failed to send the transacion");
    }

    // Stop loading
    propsHandleLoading(
      false,
      "Transaction Mined successfully, you'll need to refresh the page to see the data update in the UI"
    );
  };

  // Display the Edit Approval modal
  const handleClickDepositMatic = () => setApprovalModal(true);

  const handleChangeInputApproval = (event) => {
    const value = event.target.value;
    setAmountToDeposit(value);
  };

  const depositMatic = async () => {
    // First, hide the approval modal
    setApprovalModal(false);

    // Send the tx
    try {
      // Start loading
      propsHandleLoading(true, "Awaiting for the transaction to be confirmed");

      const uniqueTxOpt = {
        gasLimit: 250000,
        value: ethers.utils.parseEther(`${amountToDeposit}`),
      };

      const tx = await propsGwm.depositMatic(uniqueTxOpt);

      const res = await tx.wait();
      if (res.status === 1)
        propsHandleMessage(
          "success",
          "Transaction successful. Refresh this page."
        );
      else {
        propsHandleMessage("error", "Transaction failed");
      }
    } catch (err) {
      propsHandleMessage("error", err, "Failed to send the Tx");
    }

    // Stop loading
    propsHandleLoading(
      false,
      "Transaction Mined successfully, you'll need to refresh the page to see the data update in the UI"
    );

    // Reload data
    propsUpdatePettingData();
  };

  const withdrawMatic = async () => {
    // Send the tx
    try {
      // Start loading
      propsHandleLoading(true, "Awaiting for the transaction to be confirmed");
      const tx = await propsGwm.withdrawMatic(txOpt);
      const res = await tx.wait();
      if (res.status === 1)
        propsHandleMessage(
          "success",
          "Transaction successful. Refresh this page."
        );
      else {
        propsHandleMessage("error", "Transaction failed");
      }
    } catch (err) {
      propsHandleMessage("error", err, "Failed to send the Transaction");
    }

    // Stop loading
    propsHandleLoading(
      false,
      "Transaction Mined successfully, you'll need to refresh the page to see the data update in the UI"
    );

    // Reload data
    propsUpdatePettingData();
  };

  const startPetting = async () => {
    // Send the tx
    try {
      // Start loading
      propsHandleLoading(true, "Awaiting for the transaction to be confirmed");
      const tx = await propsGwm.startAndTransfer(
        Number(propsTotalGotchis),
        txOpt
      );
      const res = await tx.wait();
      if (res.status === 1)
        propsHandleMessage(
          "success",
          "Transaction successful. Refresh this page."
        );
      else {
        propsHandleMessage("error", "Transaction failed");
      }
    } catch (err) {
      propsHandleMessage("error", err, "Failed to send the Transaction");
    }

    // Stop loading
    propsHandleLoading(
      false,
      "Transaction Mined successfully, you'll need to refresh the page to see the data update in the UI"
    );

    // Reload data
    propsUpdatePettingData();
  };

  const stopPetting = async () => {
    // Send the tx
    try {
      // Start loading
      propsHandleLoading(true, "Awaiting for the transaction to be confirmed");
      const tx = await propsGwm.stopAndTransfer(txOpt);
      const res = await tx.wait();
      if (res.status === 1)
        propsHandleMessage(
          "success",
          "Transaction successful. Refresh this page."
        );
      else {
        propsHandleMessage("error", "Transaction failed");
      }
    } catch (err) {
      propsHandleMessage("error", err, "Failed to send the Transaction");
    }

    // Stop loading
    propsHandleLoading(
      false,
      "Transaction Mined successfully, you'll need to refresh the page to see the data update in the UI"
    );

    // Reload data
    propsUpdatePettingData();
  };

  if (propsIsGwm1User || propsHasDeposit) {
    return (
      <Box>
        {/* Edit Approval popup modal */}
        <Modal open={approvalModal} onClose={handleClose}>
          <Box sx={modalStyle}>
            <TextField
              sx={styleTextField}
              id="outlined-basic"
              label="Amount To Deposit"
              variant="outlined"
              defaultValue={10}
              onChange={handleChangeInputApproval}
            />
            <Box sx={styleButtonBox}>
              <Button
                sx={styleButton}
                variant="contained"
                onClick={depositMatic}
              >
                Apply
              </Button>
            </Box>
          </Box>
        </Modal>
        {/* Display the tx has been successfully mined */}

        {/* Disclaimer  */}
        {/* <Box sx={styleDisclaimer}>
          <FormControlLabel
            control={
              <Checkbox checked={checked} onChange={handleChangeChecked} />
            }
            label={
              <Typography sx={{ fontSize: "0.78rem" }}>
                By depositing tokens I understand and accept that there are
                risks related to using unaudited smart contract that could
                result in loss of all my deposited tokens. I acknowledge that I
                am solely responsible for any actions I take when using this
                smart contract and I will not hold the contract creator or any
                associated parties liable for any loss that may occur.
              </Typography>
            }
          />
        </Box> */}
        {/* Buttons stack */}
        <Box sx={styleBox}>
          {propsInteractionApproved ? (
            <Stack sx={{}} direction="row">
              {/* <Button
                variant="contained"
                onClick={handleClickDepositMatic}
                disabled={!checked}
              >
                Deposit Matic
              </Button> */}
              <Button
                variant="contained"
                onClick={withdrawMatic}
                disabled={propsIsUser || propsMaticBalanceInContract == 0}
              >
                Withdraw Matic
              </Button>
              {propsIsUser && (
                <Button
                  variant="contained"
                  onClick={stopPetting}
                  sx={{ marginLeft: "auto" }}
                >
                  Stop Petting
                </Button>
              )}
            </Stack>
          ) : (
            <Button variant="contained" onClick={approveInteraction}>
              Approve Interaction
            </Button>
          )}
        </Box>
      </Box>
    );
  } else {
    return (
      <Box>
        {/* Edit Approval popup modal */}
        <Modal open={approvalModal} onClose={handleClose}>
          <Box sx={modalStyle}>
            <TextField
              sx={styleTextField}
              id="outlined-basic"
              label="Amount To Deposit"
              variant="outlined"
              defaultValue={10}
              onChange={handleChangeInputApproval}
            />
            <Box sx={styleButtonBox}>
              <Button
                sx={styleButton}
                variant="contained"
                onClick={depositMatic}
              >
                Apply
              </Button>
            </Box>
          </Box>
        </Modal>
        {/* Display the tx has been successfully mined */}

        {/* Disclaimer  */}
        <Box sx={styleDisclaimer}>
          <FormControlLabel
            control={
              <Checkbox checked={checked} onChange={handleChangeChecked} />
            }
            label={
              <Typography sx={{ fontSize: "0.78rem" }}>
                By depositing tokens I understand and accept that there are
                risks related to using unaudited smart contract that could
                result in loss of all my deposited tokens. I acknowledge that I
                am solely responsible for any actions I take when using this
                smart contract and I will not hold the contract creator or any
                associated parties liable for any loss that may occur.
              </Typography>
            }
          />
        </Box>
        {/* Buttons stack */}
        <Box sx={styleBox}>
          {propsInteractionApproved ? (
            <Stack sx={{}} direction="row">
              <Button
                variant="contained"
                onClick={handleClickDepositMatic}
                disabled={!checked}
              >
                Deposit Matic
              </Button>
              <Button
                variant="contained"
                onClick={withdrawMatic}
                disabled={propsIsUser || propsMaticBalanceInContract == 0}
              >
                Withdraw Matic
              </Button>
              {propsIsUser ? (
                <Button
                  variant="contained"
                  onClick={stopPetting}
                  sx={{ marginLeft: "auto" }}
                >
                  Stop Petting
                </Button>
              ) : (
                <Button
                  disabled={
                    !propsInteractionApproved ||
                    !(propsMaticBalanceInContract >= propsCost) ||
                    propsTotalGotchis == 0
                  }
                  variant="contained"
                  onClick={startPetting}
                  sx={{ marginLeft: "auto" }}
                >
                  Start Petting
                </Button>
              )}
            </Stack>
          ) : (
            <Button variant="contained" onClick={approveInteraction}>
              Approve Interaction
            </Button>
          )}
        </Box>
      </Box>
    );
  }
}
