import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { CircularProgress, Typography } from "@mui/material";

const styleBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px inset rgb(63, 161, 251)",
  boxShadow: 24,
  p: 4,
  // display: "flex",
};

const styleTypography = {
  textAlign: "center",
};

const styleCircularBox = {
  marginTop: "25px",
  display: "flex",
};

const styleCircular = {
  margin: "auto",
};

export default function LoadingModal(props) {
  const propsLoading = props.loading;
  const propsSetOpen = props.setOpen;
  const propsLoadingMessage = props.loadingMessage;
  const propsSetLoading = props.setLoading;

  // const propsLoading = true;
  // const propsSetOpen = true;
  // const propsLoadingMessage =
  //   "Transaction Mined successfully, you'll need to refresh the page to see the data update in the UI";

  const handleClose = () => propsSetOpen(false);

  return (
    <Modal open={propsLoading} onClose={handleClose}>
      <Box sx={styleBox}>
        <Typography sx={styleTypography}>{propsLoadingMessage}</Typography>
        {propsLoading && (
          <Box sx={styleCircularBox}>
            {" "}
            <CircularProgress sx={styleCircular} />{" "}
          </Box>
        )}
      </Box>
    </Modal>
  );
}
